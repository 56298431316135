import React, { useEffect, useState, ReactNode } from 'react';
import debug from '../debug';

type TimeContextType = {
  time: string;
  setTime: (newTime: string | null) => void;
};

const TimeContext = React.createContext<TimeContextType>({
  time: new Date().toISOString(),
  setTime: (newTime: string | null) => {},
});


export default function TimeProvider({ children }: { children: ReactNode }) {
  const [_time, _setTime] = useState<string | null>(null);

  const time = new Date(_time || debug.now || new Date()).toISOString();

  const setTime = (newTime: string | null) => _setTime(newTime && new Date(newTime).toISOString());

  return (
    <TimeContext.Provider value={{ time, setTime }}>
      {children}
    </TimeContext.Provider>
  );
};

export const useTime = () => React.useContext(TimeContext);
