import { ReactNode } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

type Props = {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  title?: ReactNode;
  actions?: ReactNode;
  sx?: SxProps<Theme>;
};

export default function Modal({ actions, children, open, onClose, title, sx }: Props) {
  return (
    <Dialog open={open} onClose={onClose} sx={sx}>
      <Box position="absolute" top={4} right={4}>
        <Button
          color="secondary"
          sx={{
            minWidth: 'unset',
            width: '40px',
            height: '40px',
          }}
          variant="text"
          onClick={onClose}
        >
          &times;
        </Button>
      </Box>
      {title && (
        <DialogTitle
          sx={{ mb: 2, borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}` }}
        >
          <Typography variant="h5">{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {actions && (
        <DialogActions>
          <Box px="1rem" mb="0.5rem">
            {actions}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}
