
const MONTHLY_MAX = [300, 350, 380, 380, 395, 385, 350, 360, 350, 340, 330, 300];

const MONTHLY_RADIATION_CORRECTION = [467, 467, 467, 600, 700, 650, 467, 467, 467, 467, 467, 467];

const MONTHLY_X_FACTORS = [
  [400, 370, 370, 370, -185],
  [400, 370, 370, 370, -185],
  [333, 370, 185, 370, -185],
  [277.5, 555, 185, 185, 185],
  [370, 370, 0, 370, -185],
  [333, 555, 277.5, 185, -185],
  [333, 370, 370, 370, -185],
  [333, 370, 370, 370, -185],
  [333, 370, 370, 370, -185],
  [333, 370, 370, 370, -185],
  [333, 370, 370, 370, -185],
  [333, 370, 370, 370, -185],
];

const forecastSolarGeneration = (time: string, solarIntensity: number) => {
  // We carefully modeled the historical data
  // and built this algorithm here:
  // https://docs.google.com/document/d/1gcUCwhOJOJdLSSVCfeRG7nNL-mNeONmeGWlL3lCWjp4/edit

  // The API gives time in UTC
  const date = new Date(time + 'Z');
  const datetime = date.toISOString();
  // + 17 % 24 adjusts for a timezone of -7
  // but it only gives positive results
  const hour = (date.getUTCHours() + 17) % 24;
  const monthIndex = date.getUTCMonth();
  const radiationFactor = solarIntensity / MONTHLY_RADIATION_CORRECTION[monthIndex];
  // - 8 was ANOTHER attempt to adjust for
  // the timezone. It crept into the
  // spreadsheet used to develop and stayed
  // there forever.
  const hourRad = (hour - 8) / 23 * 2 * Math.PI;
  const sinHourRad = Math.sin(hourRad);
  const xs = MONTHLY_X_FACTORS[monthIndex];
  const fs = [
    xs[0] * Math.sin(sinHourRad),
    xs[1] * 1/3 * Math.sin(3 * sinHourRad),
    xs[2] * 1/5 * Math.sin(5 * sinHourRad),
    xs[3] * 1/7 * Math.sin(7 * sinHourRad),
    xs[4] * 1/9 * Math.sin(9 * sinHourRad),
  ];
  const sum = fs.reduce((sum, n) => sum + n, 0);
  const solarGen = radiationFactor * sum
  if (solarGen <= 0) {
    return {
      datetime,
      solarGen: 0,
    };
  }
  if (solarGen >= MONTHLY_MAX[monthIndex]) {
    return {
      datetime,
      solarGen: MONTHLY_MAX[monthIndex],
    };
  }
  return {
    datetime,
    solarGen,
  };
};

export default forecastSolarGeneration;
