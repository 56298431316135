import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

const Section = ({ sx = {}, children }: { sx?: SxProps, children: ReactNode }) => {
  return (
    <Box sx={{
      backgroundColor: 'white.100',
      padding: '4rem 1em',
      ...sx
    }}>
      <Box
        sx={{
          width: {
            xs: '100%',
            lg: '1200px',
          },
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Section;
