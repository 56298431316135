import { Box, Button } from '@mui/material';
import Section from '../components/Section';
import { useTime } from '../context/TimeProvider';
import Modal from '../components/Modal';
import { useState } from 'react';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import dayjs from 'dayjs';
import area from '../config/area';

type Dayjs = ReturnType<typeof dayjs>;

const normalizeHour = (hour: number) => {
  return hour < 0 ? 24 + (hour % 24) : hour;
};

const interpretTime = (time: string | null) => {
  if (! time) {
    return '';
  }
  const hours = normalizeHour(new Date(time).getUTCHours() + area.timezone_adjust_hours);
  const minutes = new Date(time).getUTCMinutes();
  return (hours > 12 ? hours - 12 : hours)
    + ':' + (minutes < 10 ? '0' : '') + minutes
    + ' ' + (hours > 12 ? 'PM' : 'AM');
};

const Header = () => {
  const [ modalOpen, setModalOpen ] = useState(false);
  const { time, setTime } = useTime()
  const currentTime = interpretTime(time);

  const closeModal = () => {
    setModalOpen(false);
  };

  const changeTime = (time: Dayjs | null) => {
    setTime(time && time.toISOString());
    closeModal();
  };

  return (
    <Section sx={{
      borderBottom: '1px solid',
      borderColor: 'grey.300',
      padding: '0',
    }}>

      <Box
        p="16px"
        display="grid"
        gap="1rem"
        gridTemplateColumns="repeat(2, 1fr)"
      >
        <Box sx={{ display:'flex' }}>
          <Box sx={{ padding: '0 .5em' }}><img src="/dominion-logo-title.png" style={{ maxHeight: '64px' }} /></Box>
        </Box>
        <Box textAlign="right">
          {area.example_temperature_f} °F | {area.name}
          <Box sx={{
            display: {
              xs: 'none',
              sm: 'inline',
            },
          }}>
            {' '}| <span onClick={() => setModalOpen(true)}>{ currentTime }</span>
          </Box>
        </Box>
      </Box>
      <Modal
        title="Time Travel"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <StaticDateTimePicker
          value={dayjs(time).tz(area.timezone_name)}
          maxDateTime={dayjs()}
          onAccept={changeTime}
          onClose={closeModal}
        />
      </Modal>
    </Section>
  );
};

export default Header;
