import { Color } from '@mui/material';
import { createTheme, PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  export interface Theme {
    palette: {
      primary: {
        main: string;
      };
      white: Partial<Color>;
      black: Partial<Color>;
      grey: Partial<Color>;
      blue: Partial<Color>;
      green: Partial<Color>;
      orange: Partial<Color>;
    };
  }
  export interface PaletteOptions {
    primary?: PaletteColorOptions;
    white?: Partial<Color>;
    black?: Partial<Color>;
    grey?: Partial<Color>;
    blue?: Partial<Color>;
    green?: Partial<Color>;
    orange?: Partial<Color>;
    red?: Partial<Color>;
  }
  interface TypographyVariants {
    label: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    projectContainer: true; // adds the `projectContainer` breakpoint
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    neutral: true;
  }
}

const WHITE_100 = '#FFFFFF';
const WHITE_200 = '#D0D0D0';

const GREEN_100 = '#EDF7ED';
const GREEN_200 = '#9BC640';
const GREEN_300 = '#62A744';
const GREEN_400 = '#59C13A';
const GREEN_500 = '#218739';
const GREEN_700 = '#018445';

const BLUE_300 = '#109AD6';
const BLUE_500 = '#215179';

const RED_500 = '#DD4444';

const GREY_100 = '#FBFDFA';
const GREY_200 = '#F8F9FB';
const GREY_300 = '#E5E9EC';
const GREY_400 = '#5F6D7D';
const GREY_500 = '#4E5959';
const GREY_700 = '#4F5758';
const GREY_800 = '#3D3935';
const GREY_900 = '#1A1A1A';

const theme = createTheme({
  palette: {
    primary: {
      main: GREY_700,
    },
    secondary: {
      main: BLUE_500,
    },
    white: {
      100: WHITE_100,
      200: WHITE_200,
    },
    grey: {
      100: GREY_100,
      200: GREY_200,
      300: GREY_300,
      400: GREY_400,
      500: GREY_500,
      700: GREY_700,
      800: GREY_800,
      900: GREY_900,
    },
    blue: {
      300: BLUE_300,
      500: BLUE_500,
    },
    green: {
      100: GREEN_100,
      200: GREEN_200,
      300: GREEN_300,
      400: GREEN_400,
      500: GREEN_500,
      700: GREEN_700,
    },
    red: {
      500: RED_500,
    },
  },
  typography: {
    fontFamily: [
      'Gotham A',
      'Gotham B',
      'din',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      color: GREY_700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      color: GREY_700,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: GREY_700,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      color: GREY_700,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: '10px 40px',
          fontWeight: 400,
          boxShadow: 'unset',
          borderRadius: '100px',
          '&:hover': {
            boxShadow: 'unset',
          },
        },
      },
    },
  },
});

export default theme;
