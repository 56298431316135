import React, { useEffect, useState, ReactNode } from 'react';
import debug from '../debug';
import { useTime } from "./TimeProvider";
import useEMaps from "../hooks/useEMaps";
import useFakeEMaps from "../hooks/useFakeEMaps";
import useOpenMeteo from "../hooks/useOpenMeteo";
import useFakeOpenMeteo from "../hooks/useFakeOpenMeteo";
import buildTimeline from '../utils/buildTimeline';

type TimelineContextType = {
  loading: boolean,
  error: typeof Error | null,
} & Partial<ReturnType<typeof buildTimeline>>;

const TimelineContext = React.createContext<TimelineContextType>({
  loading: true,
  error: null,
  timeline: undefined,
  realTime: undefined,
  realTimeBreakdown: undefined,
  maximum: undefined,
  minimum: undefined,
  highThreshold: undefined,
  lowThreshold: undefined,
});

export default function TimelineProvider({ children }: { children: ReactNode }) {
  const { time } = useTime();

  const currentHour = new Date(time);
  currentHour.setUTCMinutes(0);
  currentHour.setUTCSeconds(0);
  currentHour.setUTCMilliseconds(0);

  const forecastSource = debug.forecast || 'openmeteo';
  const shouldUseOpenMeteoForecast = forecastSource === 'openmeteo';
  const shouldUseEmapsForecast = forecastSource === 'emaps';

  const {
    loading: emapsLoading,
    error: emapsError,
    pastRange: emapsPastRange,
    forecast: emapsForecast,
  } = debug.fake
    ? useFakeEMaps()
    : useEMaps({
      currentHour,
      includeForecast: shouldUseEmapsForecast,
    });

  const {
    loading: openMeteoLoading,
    error: openMeteoError,
    forecast: openMeteoForecast,
  } = debug.fake
    ? useFakeOpenMeteo()
    : shouldUseOpenMeteoForecast
    ? useOpenMeteo()
    : {
      loading: false,
      error: null,
      forecast: undefined,
    };

  const {
    timeline,
    realTimeBreakdown,
    realTime,
    maximum,
    minimum,
    highThreshold,
    lowThreshold,
  } = emapsPastRange
    ? buildTimeline({
      emapsPastRange,
      openMeteoForecast: shouldUseOpenMeteoForecast ? openMeteoForecast : undefined,
      emapsForecast: shouldUseEmapsForecast ? emapsForecast : undefined,
      currentHour,
    })
    : {
      timeline: undefined,
      realTimeBreakdown: undefined,
      realTime: undefined,
      maximum: undefined,
      minimum: undefined,
      highThreshold: undefined,
      lowThreshold: undefined,
    };

  return (
    <TimelineContext.Provider value={{
      loading: emapsLoading || openMeteoLoading,
      error: emapsError || openMeteoError,
      realTime,
      realTimeBreakdown,
      maximum,
      minimum,
      highThreshold,
      lowThreshold,
      timeline,
    }}>
      {children}
    </TimelineContext.Provider>
  );
};

export const useTimeline = () => React.useContext(TimelineContext);
