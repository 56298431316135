
const params = new URL(window.location.href).searchParams;

const debug = {
  zone: params.get('zone') && String(params.get('zone')),
  fake: Boolean(params.get('fake')),
  log: Boolean(params.get('log')),
  now: params.get('now') && String(params.get('now')),
  forecast: params.get('forecast') && String(params.get('forecast')),
};

export default debug;
