import CssBaseline from '@mui/material/CssBaseline';
import { Head, usePage } from "@inertiajs/inertia-react";
import { Box } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import { ReactNode } from 'react';
import { IPageProps } from '../types/usePage';

const Layout = ({ children }: { children: ReactNode }) => {
  const {
    props: { title, meta = [] },
  } = usePage<IPageProps>();

  if (meta.length) {
    // There's a bug in React or Inertia that
    // prevents us from putting children in
    // <Head>. So if `meta` is ever necessary
    // we'll need to solve it.
    console.warn("`meta` tags from the server are not yet supported");
  }

  return (
    <>
      <Head title={title} />
      <CssBaseline />
      <main>
        <Box sx={{ backgroundColor: 'grey.200' }}>
          <Header />
          {children}
          <Footer />
        </Box>
      </main>
    </>
  );
};

export default (page: ReactNode) => <Layout>{page}</Layout>;
