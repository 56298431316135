import log from './log';

/**
 * Make an HTTP getter with the baseUrl and
 * headers (tokens, etc) already defined.
 */
export default function makeGetHttp(baseUrl: string, headers: Record<string, string>) {
  /**
   * Access an endpoint, check for HTTP errors,
   * and pretend we know that the response is in
   * the format specified by type `T`.
   */
  return <T>(path: string, params: Record<string, string> = {}): Promise<T> => {
    const queryString = new URLSearchParams(params).toString();
    return window.fetch(`${baseUrl}${path}?${queryString}`, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        if (response.status >= 400) {
          throw new Error('HTTP error: ' + response.status);
        } else {
          return response.json() as T;
        }
      })
      .then((json: T) => {
        log(path, json);
        return json;
      });
  };
};
