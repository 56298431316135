
const DIRECTIONAL_COEFFICIENTS = [
  {
    directionMinimum: 0,
    constant: 138,
    xs: [23.1, 5.44, -0.406],
  },
  {
    directionMinimum: 60,
    constant: 105,
    xs: [4.8, -0.736, 0.037],
  },
  {
    directionMinimum: 120,
    constant: 106,
    xs: [-0.409, 0.212, 0.0056],
  },
  {
    directionMinimum: 180,
    constant: 105,
    xs: [-1.39, 0.743, -0.0156],
  },
  {
    directionMinimum: 240,
    constant: 122,
    xs: [-3, 1.04, -0.023],
  },
  {
    directionMinimum: 300,
    constant: 94.6,
    xs: [11.1, -1.06, 0.0211],
  },
];

const WIND_MAX = 395;

const getDirectionalCoefficients = (direction: number) => {
  return DIRECTIONAL_COEFFICIENTS.find(({ directionMinimum }) => {
    return direction >= directionMinimum && direction < directionMinimum + 60
  }) || DIRECTIONAL_COEFFICIENTS[0]; // fallback to anything
};

const forecastWindGeneration = (speed: number, direction: number) => {
  // We carefully modeled the historical data
  // and built this algorithm here:
  // https://docs.google.com/document/d/1gcUCwhOJOJdLSSVCfeRG7nNL-mNeONmeGWlL3lCWjp4/edit

  // % 360 yields 0 <= direction < 360
  const { constant, xs } = getDirectionalCoefficients(direction % 360);
  const windGen = xs[0] * speed
    + xs[1] * Math.pow(speed, 2)
    + xs[2] * Math.pow(speed, 3)
    + constant;
  return Math.min(windGen, WIND_MAX);
};

export default forecastWindGeneration;
