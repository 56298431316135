import { Breakdown, PowerBreakdownPastRange, CarbonIntensityForecast } from '../hooks/useEMaps';
import { OpenMeteoForecast } from '../hooks/useOpenMeteo';
import forecastSolarGeneration from '../utils/forecastSolarGeneration';
import forecastWindGeneration from '../utils/forecastWindGeneration';
import log from './log';

type SourceBreakdown = {
  wind: number;
  solar: number;
  gas: number;
  coal: number;
  unknown: number;
};

type HistoricalIntensityPoint = {
  datetime: string;
  intensity: number;
  sources: SourceBreakdown;
};

type ForecastIntensityPoint = {
  datetime: string;
  intensity: number;
  sources: null;
};

type IntensityPoint = HistoricalIntensityPoint | ForecastIntensityPoint;

/**
 * If we replace all possible nulls with 0's
 * the language will happily let us add them
 * together
 */
const normalizeBreakdown = (breakdown: Breakdown) => {
  return {
    'nuclear': breakdown['nuclear'] || 0,
    'geothermal': breakdown['geothermal'] || 0,
    'biomass': breakdown['biomass'] || 0,
    'coal': breakdown['coal'] || 0,
    'wind': breakdown['wind'] || 0,
    'solar': breakdown['solar'] || 0,
    'hydro': breakdown['hydro'] || 0,
    'gas': breakdown['gas'] || 0,
    'oil': breakdown['oil'] || 0,
    'unknown': breakdown['unknown'] || 0,
    'hydro discharge': breakdown['hydro discharge'] || 0,
    'battery discharge': breakdown['battery discharge'] || 0,
  };
};

/**
 * Use the API results to build an array of
 * intensity points in the format we prefer.
 * This is also a good time to pull out
 * thresholds and other meta-data around
 * historical intensity points.
 */
const buildHistorical = (emapsPastRange: PowerBreakdownPastRange) => {
  const hours = emapsPastRange.data;
  const historicalIntensity: HistoricalIntensityPoint[] = hours.map((hour) => {
    const { wind, solar, gas, coal, unknown } = normalizeBreakdown(hour.powerProductionBreakdown);
    const nonRenewable = gas + coal + unknown;
    const total = wind + solar + gas + coal + unknown;
    return {
      datetime: hour.datetime,
      intensity: nonRenewable / total,
      sources: { wind, solar, gas, coal, unknown },
    };
  });

  const sorted = historicalIntensity
    .map(({ intensity }) => intensity)
    .sort();
  const maximum = sorted[sorted.length - 1];
  const minimum = sorted[0];
  const highThreshold = sorted[Math.floor(2/3 * sorted.length)];
  const lowThreshold = sorted[Math.floor(1/3 * sorted.length)];
  const sourceMaximums = {
    wind: Math.max(...hours.map(({ powerConsumptionBreakdown: { wind } }) => wind || 0)),
    solar: Math.max(...hours.map(({ powerConsumptionBreakdown: { solar } }) => solar || 0)),
    gas: Math.max(...hours.map(({ powerConsumptionBreakdown: { gas } }) => gas || 0)),
    coal: Math.max(...hours.map(({ powerConsumptionBreakdown: { coal } }) => coal || 0)),
    unknown: Math.max(...hours.map(({ powerConsumptionBreakdown: { unknown } }) => unknown || 0)),
  };
  return {
    historicalIntensity,
    maximum,
    minimum,
    highThreshold,
    lowThreshold,
    sourceMaximums,
  };
};

/**
 * Use the eMaps API's forecast data to build an
 * array of data in the format we prefer.
 *
 * We fudge a bit here because we don't know the
 * source values. We only know the "intensity"
 * that emaps gives. Because of that we have to
 * use the maxIntensity from the historical data
 * as our assumption of the max value for *this*
 * data too.
 */
const buildForecastFromEmaps = (
  emapsForecast: CarbonIntensityForecast,
  historicalIntensityPoints: IntensityPoint[],
): ForecastIntensityPoint[] => {
  const maxIntensity = Math.max(...historicalIntensityPoints.map(({ intensity }) => intensity));
  const hours = emapsForecast.forecast;
  const max = Math.max(...hours.map(({ carbonIntensity }) => carbonIntensity));
  return hours.map((hour) => {
    return {
      datetime: hour.datetime,
      intensity: maxIntensity * hour.carbonIntensity / max,
      sources: null,
    };
  });
};

/**
 * Use the OpenMeteo API's weather forecast data
 * to build an array of energy data in the format
 * we prefer.
 *
 * Like above, we fudge a bit here. This time it
 * is because we don't know the output values of
 * the carbon-emitting sources.
 */
const buildForecastFromOpenMeteo = (
  openMeteoForecast: OpenMeteoForecast,
  historicalIntensityPoints: IntensityPoint[],
): ForecastIntensityPoint[] => {
  const {
    time,
    direct_radiation,
    windspeed_10m,
    winddirection_10m,
  } = openMeteoForecast.hourly;
  const renewableOutput = time.map((t, i) => {
    const { datetime, solarGen } = forecastSolarGeneration(t, direct_radiation[i]);
    const windGen = forecastWindGeneration(windspeed_10m[i], winddirection_10m[i]);
    return {
      datetime,
      output: solarGen + windGen,
    };
  });
  const lastHistoricalPoint = historicalIntensityPoints[historicalIntensityPoints.length - 1];
  const sameForecastPoint = renewableOutput.find(
    (point) => point.datetime === lastHistoricalPoint.datetime
  );
  if (sameForecastPoint) {
    // If the historical data contains a datetime
    // that aligns with the forecast data, then
    // it tells us exactly what an intensity
    // score for that moment should be.
    //
    // We keep using "1 - x" here because
    // renewables + carbon producers = 100%
    const factor = sameForecastPoint.output / (1 - lastHistoricalPoint.intensity);
    const bound = (n: number) => Math.max(0, Math.min(1, n));
    return renewableOutput.map(({ datetime, output }) => {
      const intensity = bound(1 - (output / factor));
      return {
        datetime,
        intensity,
        sources: null,
      };
    });
  } else {
    // If the historical data and the forecast
    // data do not overlap it's likely that
    // someone is using the time machine. In that
    // case a forecast isn't even needed.
    return [];
  }
};

/**
 * Combine the historical intensity points and
 * the forecast intensity points into a single
 * array, while keeping all that juicy meta data
 * and also picking out the one point that
 * should represent "now".
 */
const compile = (
  history: {
    historicalIntensity: HistoricalIntensityPoint[];
    maximum: number;
    minimum: number;
    highThreshold: number;
    lowThreshold: number;
    sourceMaximums: SourceBreakdown;
  },
  forecastIntensity: ForecastIntensityPoint[],
  currentHour: Date,
) => {
  const {
    historicalIntensity,
    maximum,
    minimum,
    highThreshold,
    lowThreshold,
    sourceMaximums,
  } = history;

  const lastHistoricalDate = new Date(
    historicalIntensity[historicalIntensity.length - 1].datetime
  );

  const timeline: IntensityPoint[] = [
    ...historicalIntensity,
    ...forecastIntensity.filter(({ datetime }) => new Date(datetime) > lastHistoricalDate),
  ];

  const currentHourString = currentHour.toISOString();

  const realTime = timeline.find(({ datetime }) => datetime === currentHourString);

  const realTimeBreakdown = realTime?.sources && sourceMaximums
    ? {
      wind: realTime.sources.wind / sourceMaximums.wind,
      solar: realTime.sources.solar / sourceMaximums.solar,
      gas: realTime.sources.gas / sourceMaximums.gas,
      coal: realTime.sources.coal / sourceMaximums.coal,
      unknown: realTime.sources.unknown / sourceMaximums.unknown,
    }
    : undefined;

  return {
    timeline,
    maximum,
    minimum,
    highThreshold,
    lowThreshold,
    sourceMaximums,
    realTimeBreakdown,
    realTime,
  };
};

type Props = {
  emapsPastRange: PowerBreakdownPastRange;
  emapsForecast?: CarbonIntensityForecast;
  openMeteoForecast?: OpenMeteoForecast;
  currentHour: Date;
};

const buildTimeline = ({
  emapsPastRange,
  emapsForecast,
  openMeteoForecast,
  currentHour,
}: Props) => {
  const history = buildHistorical(emapsPastRange);

  const forecastIntensity = emapsForecast
    ? buildForecastFromEmaps(emapsForecast, history.historicalIntensity)
    : openMeteoForecast
    ? buildForecastFromOpenMeteo(openMeteoForecast, history.historicalIntensity)
    : [];

  const {
    timeline,
    maximum,
    minimum,
    highThreshold,
    lowThreshold,
    realTimeBreakdown,
    realTime,
  } = compile(history, forecastIntensity, currentHour);

  log('Compiled results:', {
    timeline,
    realTimeBreakdown,
    realTime,
    highThreshold,
    lowThreshold,
  });

  return {
    timeline,
    realTimeBreakdown,
    realTime,
    maximum,
    minimum,
    highThreshold,
    lowThreshold,
  };
};

export default buildTimeline;
