import { Box, Link, Stack } from "@mui/material";
import Section from "../components/Section";


const Footer = () => {
  return (
    <Section
      sx={{
        backgroundColor: "grey.200",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: "grey.300",
        }}
        p="16px"
        display="grid"
        gap="1rem"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
        }}
      >
        <Box sx={{ display: "flex", marginTop: "7px" }}>
          <Box sx={{ padding: "0 .5em" }}>
            <img src="/bellawatt-square.svg" style={{ width: "24px" }} />
          </Box>{" "}
          A product by&nbsp;
          <Link href="https://www.bellawatt.com/" target="_blank">
            Bellawatt
          </Link>
          .
        </Box>
        <Box
          textAlign={{
            xs: "left",
            lg: "center",
          }}
        >
          <Stack
            direction={{
              xs: "column",
              lg: "row",
            }}
            justifyContent="flex-end"
          >
            <Box p={1}>
              <Link href="https://www.bellawatt.com/about" target="_blank">
                About
              </Link>
            </Box>
            <Box p={1}>
              <Link
                href="https://www.bellawatt.com/#case-studies"
                target="_blank"
              >
                Case Studies
              </Link>
            </Box>
            <Box p={1}>
              <Link href="https://www.bellawatt.com/work" target="_blank">
                How We Work
              </Link>
            </Box>
            <Box p={1}>
              <Link href="https://www.bellawatt.com/delivery" target="_blank">
                Delivery
              </Link>
            </Box>
          </Stack>
        </Box>
        <Box
          textAlign={{
            xs: "left",
            lg: "right",
          }}
        ></Box>
      </Box>
      <Box
        p="16px"
        display="grid"
        gap="1rem"
        gridTemplateAreas={{
          xs: `"links"
               "copyright"`,
          lg: '"copyright links"',
        }}
      >
        <Box sx={{ gridArea: "copyright" }}>
          <Box p={1}>&copy; 2023. All Rights Reserved.</Box>
        </Box>
        <Box
          textAlign={{
            xs: "left",
            lg: "right",
          }}
          sx={{ gridArea: "links" }}
        >
          <Stack
            direction={{
              xs: "column",
              lg: "row",
            }}
            justifyContent="flex-end"
          >
            <Box p={1}>Term of Service</Box>
            <Box p={1}>Cookie Policy</Box>
            <Box p={1}>Privacy Policy</Box>
            <Box p={1}>Legal Notice</Box>
            <Box p={1}>Language and Region: English (US)</Box>
          </Stack>
        </Box>
      </Box>
    </Section>
  );
};

export default Footer;
